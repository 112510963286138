<template lang="pug">
v-container(fluid v-if='Contenido')
    v-row
        v-img(  :src='ciardacademy.banner' height='80vh' ).d-none.d-md-flex
            v-container.fill-height
                v-row.justify-center.align-center
                    v-col(cols=12 md=3  style='background-color: rgba(0, 0, 0, .3); margin-bottom: 1em').justify-center.align-center
                        h2.font-weight-bold.pb-2.text-uppercase.white--text(v-html='capacitacion.titulo').text-center
    v-row.white.justify-center.align-center
        v-col(cols=10 md=7 style="margin-bottom: 1em; margin-top: 1em")
            h1.pb-2.text-uppercase.azul--text.justify-center.text-center.d-none.d-md-block Entérate de nuestras capacitaciones
            h2.text-uppercase.azul--text.justify-center.text-center.d-md-none Entérate de nuestras capacitaciones

    v-row.justify-center.align-center(cols=12 md=7 style="margin-bottom: 0em;")
        hr.primary.hr
    v-row
        v-container.pb-12
            v-row(v-for='(blog, index) of registroDeCapacitaciones' :key='blog.id' 
            :style='`flex-direction: ${condicion(index) ? "row-reverse" : "row"}`').mt-4
                v-col(cols=12  md=4 :class='condicion(index) ? "pl-12" : ""').d-none.d-md-block 
                    v-img(:src='blog.portada').d-none.d-md-block   
                v-col(cols=12 md=8 :class='condicion(index) ? "" : "pl-12"').d-none.d-md-block 
                    v-row
                        h3.text-uppercase.d-none.d-md-block.primary--text {{ blog.título }}
                    v-row
                        hr.primary.hrTitulo
                    v-row
                        p(v-html='blog.contenido').text-justify
                    
                 
                v-col(cols=12 ).d-md-none
                    v-img(:src='blog.portada' height='100px' contain)
                    v-row.justify-center.align-center.pt-3 
                    
                        h3.text-uppercase.primary--text {{ blog.título }}
                    v-row.justify-center.align-center 
                        hr.primary.hrTitulo
                
                    v-row.justify-center.align-center 
                        v-col(cols=11 )
                            p.text-caption(v-html='blog.contenido').text-justify
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        descripcionCorta(descripcion){
            return `${descripcion.substring(0, 300)}...`
        },
        ...mapMutations({
            setItem: 'Blog/setItem',
        }),
        dirigirA(item){
            this.setItem(item)
            this.$router.push({name: 'entradaBlog', params: {idEvento: item.id}})
        },
        condicion(indice){
            let index = indice +1
            if(index % 2 === 0) return true
            else return false 
        }
    },
    computed: {
        Eventos(){
            return this.Contenido.eventos
        },
        ...mapGetters({
            registro: 'Blog/registro',
        }),
        registroDeCapacitaciones(){
           return this.registro.map((item)=>({...item, etiquetas: item.etiquetas.map(({id})=>id)}))
                        .filter(({etiquetas})=>etiquetas.some((idEtiqueta)=>idEtiqueta === 'Rc8J3VXvK7L8eGEx23vX'))
        },
        capacitacion(){
          if(this.Contenido)  return this.Contenido.ciardacademy.capacitacion
        }
    },
    created(){
        this.$store.dispatch('Blog/obtener_todos')
    },
}
</script>
<style lang="sass" scoped>
.hr
    width: 90%
    height: 5px
    margin: 0px!important
    opacity: 1!important
    border: 0px
.hrTitulo
    width: 100%
    height: 3px
    margin: 0px!important
    opacity: 1!important
    border: 0px

.centrado
    padding-top: 30%!important

</style>
